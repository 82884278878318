"use client";
import { Logo } from "@/icons/Logo";
import Link from "next/link";
import TelegramIcon from "@/icons/TelegramIcon";
import WhatsAppIcon from "@/icons/WhatsAppIcon";
import XIcon from "@/icons/XIcon";
import InstagramIcon from "@/icons/InstagramIcon";
import FacebookIcon from "@/icons/FacebookIcon";
import {
  ALAKHBAR_FACEBOOK,
  ALAKHBAR_INSTAGRAM,
  ALAKHBAR_TELEGRAM,
  ALAKHBAR_WHATSAPP,
  ALAKHBAR_X,
  ALAKHBAR_YOUTUBE,
} from "@/constants";
import { useMenus } from "@pulsex/hooks";
import pulseConfig from "@/pulsex.config";
import YouTubeIcon from "@/icons/YoutubeIcon";
import React from "react";
import { locale } from "@/locale";
import { cn } from "@pulsex/utils";

export const Footer = ({ isMobile }: any) => {
  const { menus, status } = useMenus(pulseConfig);
  const currentYear = new Date().getFullYear();
  const iconSize = 23;
  return (
    <div
      className={cn(
        "mx-auto flex h-fit items-center justify-center bg-black sm:block lg:flex",
        pulseConfig.LANGUAGE !== "ar" && "flex-row-reverse"
      )}
    >
      <div className="!mb-0 hidden w-[20%] justify-center lg:block lg:!h-full lg:!min-h-full rtl:lg:border-l-2 ltr:lg:border-r-2 lg:p-8">
        <Logo size={90} className="fill-white max-w-full" />
      </div>
      <div
        className={cn(
          "mx-auto block py-2 text-center rtl:md:mr-0 ltr:ml-0 lg:!flex lg:w-[80%] rtl:lg:items-start ltr:lg:items-end lg:!justify-between lg:px-5 lg:text-start",
          pulseConfig.LANGUAGE !== "ar" && "flex-row-reverse"
        )}
      >
        <div
          className={cn(
            "flex w-full flex-col items-center lg:w-[75%] rtl:lg:items-start ltr:lg:items-end max-md:px-4",
            pulseConfig.LANGUAGE !== "ar" && "text-left"
          )}
        >
          <p className="max-md:text-center text-sm text-masterYellow md:text-lg font-amine-light leading-2">
            {locale.footerSubMessage}
            <span className="mt-1 h-fit text-sm sm:text-lg">©</span>

            {currentYear}
          </p>
          <p className="max-md:text-center max-md:mt-1 text-sm text-masterYellow font-amine-light leading-2 md:text-lg">
            {locale.footerMainMessage}
          </p>
          <div className="flex lg:w-[80%] rtl:lg:justify-start ltr:lg:justify-end">
            <ul className="mt-2 flex items-start justify-start text-sm lg:text-lg font-black text-white rtl:lg:mr-0 ltr:lg:ml-0">
              {menus
                ?.getMenuByKey("h-footer")
                ?.map((menuItem: any, index: number) => (
                  <li
                    key={index}
                    className="ml-1.5 rtl:border-l  ltr:border-r rtl:border-gray-text rtl:pl-1.5 ltr:pr-1.5 last:border-none font-amine-light"
                  >
                    <Link href={menuItem?.url || ""}>{menuItem?.title} </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="mx-auto flex w-full items-center justify-center text-white sm:w-fit sm:items-end sm:justify-between lg:m-0 lg:flex lg:justify-start">
          <div className="flex flex-col items-center justify-center sm:flex-row sm:items-start sm:justify-start lg:flex lg:items-start lg:justify-start">
            <div className="flex flex-col items-center justify-center rtl:sm:items-start ltr:sm:items-end">
              <p className="text-lg font-bold lg:pt-0 mb-1">{locale.socialPages}</p>
              <div className="flex items-center justify-center">
                {menus
                  ?.getMenuByKey("h-footer-socials")
                  ?.map((menuItem: any, index: number) => {
                    const socialIcons: Record<string, JSX.Element> = {
                      facebook: (
                        <a
                          href={menuItem?.url || ALAKHBAR_FACEBOOK}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="facebook footer"
                          className="ml-1.5 flex h-fit w-fit items-center justify-center rounded-full bg-white hover:bg-white/75 p-2"
                        >
                          <FacebookIcon
                            size={iconSize}
                            className="fill-black"
                          />
                        </a>
                      ),
                      instagram: (
                        <a
                          href={menuItem?.url || ALAKHBAR_INSTAGRAM}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="instagram footer"
                          className="ml-1.5 flex h-fit w-fit items-center justify-center rounded-full bg-white hover:bg-white/75 p-2"
                        >
                          <InstagramIcon
                            size={iconSize}
                            className="stroke-black"
                          />
                        </a>
                      ),
                      twitter: (
                        <a
                          href={menuItem?.url || ALAKHBAR_X}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="x footer"
                          className="ml-1.5 flex h-fit w-fit items-center justify-center rounded-full bg-white hover:bg-white/75 p-2"
                        >
                          <XIcon size={iconSize} className="fill-black" />
                        </a>
                      ),
                      whatsapp: (
                        <a
                          href={menuItem?.url || ALAKHBAR_WHATSAPP}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="whatsapp footer"
                          className="ml-1.5 flex h-fit w-fit items-center justify-center rounded-full bg-white hover:bg-white/75 p-2"
                        >
                          <WhatsAppIcon
                            size={iconSize}
                            className="fill-black"
                          />
                        </a>
                      ),
                      telegram: (
                        <a
                          href={menuItem?.url || ALAKHBAR_TELEGRAM}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="telegram footer"
                          className="ml-1.5 flex h-fit w-fit items-center justify-center rounded-full bg-white hover:bg-white/75 p-2"
                        >
                          <TelegramIcon
                            size={iconSize}
                            className="fill-black"
                          />
                        </a>
                      ),
                      youtube: (
                        <a
                          href={menuItem?.url || ALAKHBAR_YOUTUBE}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="telegram footer"
                          className="ml-1.5 flex h-fit w-fit items-center justify-center rounded-full bg-white hover:bg-white/75 p-2"
                        >
                          <YouTubeIcon size={iconSize} className="fill-black" />
                        </a>
                      ),
                    };
                    return (
                      <React.Fragment key={index}>
                        {socialIcons[menuItem.title.toLowerCase()]}
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
