"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = postPageView;
async function postPageView(config, userId, documentId, type) {
    const baseUrl = config["TRACK_BASE_URL"];
    const clientId = config["CLIENT_ID"];
    const params = new URLSearchParams({
        clientId,
        documentId,
        type,
    });
    if (userId) {
        params.append("userId", userId);
    }
    const url = `${baseUrl}/mangopulse/event/page-view?${params.toString()}`;
    const response = await fetch(`${url}`);
    if (!(response === null || response === void 0 ? void 0 : response.ok)) {
        console.error(`Failed to send page view: ${response === null || response === void 0 ? void 0 : response.statusText}`);
    }
    if (response) {
        try {
            return "ok";
        }
        catch (error) {
            console.error("Error parsing JSON:", error);
            return;
        }
    }
}
