import LoginIcon from "@/icons/LoginIcon";
import { Logo } from "@/icons/Logo";
import { SearchIcon } from "@/icons/SearchIcon";
import UserIcon from "@/icons/UserIcon";
import { cn } from "@pulsex/utils";
import React from "react";

type Props = { isMobile?: any };

const MenuSkeleton = ({ isMobile }: Props) => {
  return (
    <>
      <div className="max-md:py-3.5 bg-neutral-300/40  lg:mb-6 lg:mt-4">
        <div className="w-full max-md:h-[118px] h-[160px]" />
      </div>
    </>
  );
};

export default MenuSkeleton;
